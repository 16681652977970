import NavBar from './NavBar';
import Footer from './Footer';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  padding: {
    backgroundColor: '#EBF7FD',
    high: 'auto',
    width: '100%',
  },
}));
export default function Layout({ children }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={12} xl={12}>
        <NavBar />
      </Grid>
      <Grid item xs={12} md={12} xl={12}>
        <Grid className={classes.padding}>{children}</Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}
