import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'next/link';



const useStyles = makeStyles((theme) => ({
  root: {
    height: "7rem",
    lineHeight: "1.4rem",
    color: "#84A1B5",
    borderTop: "1px solid #BCDCF2",
    backgroundColor: "white",
    position: "relative",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      height:"9rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding:"1rem",
      height:"9rem",

    },
  },

  navAlign: {
    textAlign: "center",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
    lineHeight: "0.4rem",
    paddingBottom:"1rem",

    },
  },

  text: {
    textAlign: "center",
  },

  hideOnXS: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  showOnXS: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  removeSpace: {
    textDecoration: "none",
    color: "#84A1B5",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0,

    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
    },
  },
}));



export default function Footer() {
  const classes = useStyles();
  return (

    <Grid container className={classes.root} >
    <Grid container item  justifyContent="center" alignItems="center" alignContent="center">
       <Grid item lg={6} md={6} sm={12} xs={12}>

          <p className={classes.text}>
            © {new Date().getFullYear()} Life Without Laundry, All Rights
            Reserved.
          </p>
        </Grid>


        {/* Start LG to SM devices footer menu */}

        <Grid item className={classes.hideOnXS} 
         lg={6} md={6} sm={12} xs={12}>
          <nav className={classes.navAlign}>

            <Link  href='/about'  passHref>
                <a className={classes.removeSpace}>About</a>
            </Link>

            <Link  href='/terms-and-conditions'  passHref>
                <a className={classes.removeSpace}>{`Terms & Conditions`}</a>
            </Link>

            <Link  href='/contact-us' passHref>
              <a className={classes.removeSpace}>Contact</a>
            </Link>
          </nav>
        </Grid>
        {/* END LG to SM devices footer menu */}
        {/* Start XS devices footer menu */}
        <Grid className={classes.showOnXS} item lg={6} md={6} sm={12} xs={12}>
          <nav className={classes.navAlign}>

            <Link className={classes.removeSpace} href='/about' passHref>
              <p>
                <a className={classes.cursor}>About</a>
              </p>
            </Link>

            <Link className={classes.removeSpace} href='/terms-and-conditions' passHref>
              <p>
                <a className={classes.cursor}>{`Terms & Conditions`}</a>
              </p>
            </Link>

            <Link className={classes.removeSpace} href='/contact-us' passHref>
              <p>
                <a className={classes.cursor}>Contact</a>
              </p>
            </Link>
          </nav>
        </Grid>

        {/* END XS devices footer menu */}

      </Grid>
    </Grid>
  );
}
