import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TemporaryDrawer from "./Drawer";
// import Image1 from "../../public/logo-lwl.svg";
import Router from "next/router";
import { useRouter } from "next/router";
import PersonIcon from "@mui/icons-material/Person";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margineBottom: "1rem",
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.4rem",
    fontWeight: "400",
    fontFamily: '"Lato",  sans-serif',
  },
  showbar: {
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  showbar2: {
    display: "none",
    [theme.breakpoints.down(960)]: {
      display: "block",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  buton1: {
    background: "#0073A8",
    marginRight: 7,
    marginLeft: 10,
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.4rem",
    fontFamily: '"Lato",  sans-serif',
    "&:hover": {
      backgroundColor: "#035a82",
      opacity: "0.9",
      color: "#fff",
    },
  },
  buton2: {
    background: "#0073A8",
    marginRight: 7,
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.4rem",
    fontWeight: "400",
    fontFamily: '"Lato",  sans-serif',
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    textDecoration: "none",
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.4rem",
    fontWeight: "400",
    fontFamily: '"Lato",  sans-serif',
    "&:hover": {
      backgroundColor: "#28394a",
      opacity: "0.9",
      color: "#fff",
    },
  },
  img1: {
    maxWidth: "60px",
    objectFit: "contain",
    // marginLeft: "7rem",
    cursor: " pointer",
  },
  img2: {
    maxWidth: "270px",
    maxHeight: "30.17px",
    objectFit: "contain",
    cursor: " pointer",
  },
}));



const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

const getItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.getItem(key);
  } return value;
}

const removeItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.removeItem(key);
  } return value;
}

//zipcode

const setZipcode = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

export default function NavBar() {
  const classes = useStyles();

  const router = useRouter();

  const token = router.query.token;
  const zipCode = router.query.q;

  const [nextToken, setsessionToken] = useState(false)



  const redirect = () => {
    const path =
      process.env.NEXT_PUBLIC_WEBAPP || "https://new-app.lifewithoutlaundry.com/";
    window.location.assign(path);
  };

  const redirectSignup = () => {
    const path =
      `${process.env.NEXT_PUBLIC_WEBAPP}/signup` ||
      "https://new-app.lifewithoutlaundry.com/signup"; 
    window.location.assign(path);
  };

  const redirectMyaccount = () => {
    const path =
      `${process.env.NEXT_PUBLIC_WEBAPP}/myaccount` ||
      "https://new-app.lifewithoutlaundry.com/myaccount"; 
    window.location.assign(path);
  };


  const logout = () => { 
    removeItem('accessToken', token);
    removeItem('defaultZipCode', zipCode);

    const path =
    `${process.env.NEXT_PUBLIC_WEBAPP}/logout` ||
    "https://new-app.lifewithoutlaundry.com/logout"; 
  window.location.assign(path);
  }



  useEffect(() => {
    if (token) {
      setItem('accessToken', token);
      setZipcode('defaultZipCode', zipCode);
    } else if (!nextToken) {
      removeItem('accessToken', token);
      removeItem('defaultZipCode', zipCode);
    }
    const value = getItem('accessToken');
    setsessionToken(value);
    // else window.sessionStorage.removeItem('accessToken', token);
    // eslint-disable-next-line 
  }, [token])

  return (
    <div className={classes.root}>
      <AppBar style={{ backgroundColor: "#101d27" }} position="static">
        <Toolbar>
          <Grid container alignItems="center" className={classes.showbar2}>
            <Grid container item lg={12} sm={12} xs={12} >
              <Grid item sm={2} xs={2} className={classes.item}>
                <TemporaryDrawer />
              </Grid>
              <Grid item sm={8} xs={8} className={classes.center}>

                {
                  // eslint-disable-next-line 
                }<img
                  className={classes.img1}
                  src="/logo-lwl.svg"
                  alt="Life Without Laundry Logo"
                  onClick={() => Router.push("/")}
                />

              </Grid>

            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.showbar}>
            <Grid item lg={6} md={5} sm={5} >
              <div className={classes.center}>

                {
                  // eslint-disable-next-line 
                }<img
                  className={classes.img2}
                  src="/logo-main.svg"
                  alt="Life Without Laundry Logo"
                  onClick={() => Router.push("/")}
                />

              </div>
            </Grid>
            <Grid item lg={6} md={7} sm={7} className={classes.center} >
              <>

                <Button
                  className={classes.btn}
                  onClick={() => Router.push("/about")}
                >
                  About
                </Button>
                <Button
                  className={classes.btn}
                  onClick={() => Router.push("/services")}
                >
                  Services
                </Button>

                <Button
                  className={classes.btn}
                  onClick={() => Router.push("/faq")}
                >
                  FAQ
                </Button>


                {nextToken && (
                  <Button onClick={redirectMyaccount} className={classes.buton1}>
                    {" "}
                    <HomeIcon fontSize="small" style={{marginRight:"2px"}}/>
                    Home
                    
                  </Button>
                )}

                {!nextToken && (
                  <Button onClick={redirect} className={classes.buton1}>
                    {" "}
                    Login
                  </Button>
                )}

                {nextToken && (
                  <Button onClick={logout} className={classes.buton1}>
                    {" "}
                    <PowerSettingsNewIcon />
                    Logout
                  </Button>
                )}

                {!nextToken && (
                  <Button onClick={redirectSignup} className={classes.buton2}>
                    {" "}
                    Signup
                  </Button>
                )}
              </>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
