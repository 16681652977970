import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Router from "next/router";
import { useRouter } from "next/router";


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  para: {
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontSize: '0.8rem',
    padding: 5,
    margin: 0,
  },
  iconbtn: {
    backgroundColor: '#aacbe9',
    padding: '0.4rem 0.6rem',
    borderRadius: 5,
    margin: 0,
    "&:hover": {
      backgroundColor: '#9CBBD8', 
    },
  },
}));


const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

const getItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.getItem(key);
  } return value;
}

const removeItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.removeItem(key);
  } return value;
}

//zipcode

const setZipcode = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
} 



export default function TemporaryDrawer() {
  const classes = useStyles();

  // Start Router

  const router = useRouter();

  const token = router.query.token;
  const zipCode = router.query.q;

  const [nextToken, setsessionToken] = useState(false)

  const redirect = () => {
    const path =
      `${process.env.NEXT_PUBLIC_WEBAPP}` ||
      `https://lwl-react-app-qa.web.app/`;
    // console.log(path);
    window.location.assign(path);
  };


  const redirectMyaccount = () => {
    const path =
      `${process.env.NEXT_PUBLIC_WEBAPP}/myaccount?token=${token}` ||
      `https://lwl-react-app-qa.web.app/myaccount?token=${token}`;
    // console.log(path);
    window.location.assign(path);
  };
  // END Router




  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };




  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{
        backgroundColor: '#222222',
        height: '100%',
      }}
    >
      <List>

        <div>

          {!nextToken && (<ListItem button onClick={() => Router.push("/")}
            style={{ backgroundColor: '#333', }}>
            <ListItemText>
              <p className={classes.para}>&nbsp;&nbsp;&nbsp;Home</p>
            </ListItemText>
          </ListItem>)}
          <Divider />

          {nextToken && (<ListItem button onClick={redirectMyaccount}
            style={{ backgroundColor: '#333', }}>
            <ListItemText>
              <p className={classes.para}>&nbsp;&nbsp;&nbsp; My Account</p>
            </ListItemText>
          </ListItem>)}
          <Divider />

          <ListItem button onClick={() => Router.push("/about")}
            style={{ backgroundColor: '#333', }}>
            <ListItemText>
              <p className={classes.para}>&nbsp;&nbsp;&nbsp;About</p>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem button onClick={() => Router.push("/services")}
            style={{ backgroundColor: '#333', }}>
            <ListItemText>
              <p className={classes.para}>&nbsp;&nbsp;&nbsp;Services</p>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem button onClick={() => Router.push("/faq")}
            style={{ backgroundColor: '#333', }}>
            <ListItemText>
              <p className={classes.para}>&nbsp;&nbsp;&nbsp;F.A.Q.</p>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem button onClick={() => Router.push("/contact-us")}
            style={{ backgroundColor: '#333', }}>
            <ListItemText>
              <p className={classes.para}>&nbsp;&nbsp;&nbsp;Contact</p>
            </ListItemText>
          </ListItem>
          <Divider />


          {!nextToken && (
            <ListItem button onClick={redirect}
              style={{ backgroundColor: '#333', }}>

              <ListItemText>
                <p className={classes.para}>&nbsp;&nbsp;&nbsp;Login</p>
              </ListItemText>
            </ListItem>)}
          <Divider />



        </div>

      </List>
    </Box>
  );


  useEffect(() => {
    if (token) {
      setItem('accessToken', token);
      setZipcode('defaultZipCode', zipCode);
    } else if (!nextToken) {
      removeItem('accessToken', token);
      removeItem('defaultZipCode', zipCode);
    }
    const value = getItem('accessToken');
    setsessionToken(value);
    // else window.sessionStorage.removeItem('accessToken', token);
// eslint-disable-next-line 
  }, [token])

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            className={classes.iconbtn}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon
              style={{
                color: 'black',
                fontSize: '1.1rem',
              }}
            />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
